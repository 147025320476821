import { FC } from 'react';
import { ColumnConfig } from '../../../../models/TableView';
import { CheckboxFilterValue } from '../../../../models/TableViewFilters';
import Badge from '../../../shared/badge/Badge';
import { useTranslation } from 'react-i18next';

const CheckboxFilterOverview: FC<ColumnConfig> = (props) => {
  const { filter } = props;
  const checkboxFilters = filter as CheckboxFilterValue | undefined;
  const { t } = useTranslation('table-view');

  return (
    <div className="flex flex-wrap items-center gap-2">
      <Badge
        textClass="text-dpm-gray-2 !font-normal"
        text={checkboxFilters === false ? t('filters.checkbox.unchecked') : t('filters.checkbox.checked')}
      />
    </div>
  );
};

export default CheckboxFilterOverview;
